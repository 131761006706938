<script setup lang="ts">
import { computed } from 'vue'

import Icon from '@/components/Icon/Icon.vue'
import { CarouselWrapper, CarouselItem } from '@/components/global/Carousel'
import useFeatureFlag from '@/composables/useFeatureFlag'
import { useRouteMeta } from '@/composables/useRouteMeta'
import useUser from '@/composables/useUser'
import appConfig from '@/services/appConfig'
import { t } from '@/services/translations'

type Benefit = {
  icon: string
  iconStyle: string
  label: string
}

const { hasSimpleHeaderLayout } = useRouteMeta()
const { isUserSignedIn } = useUser()

const { data: alternativeBenefitsFlag } = useFeatureFlag('alternative-benefits', false)

const isBenefitsVisible = computed(() => (!isUserSignedIn ? !hasSimpleHeaderLayout.value : false))

const benefitsStrip = computed(() => {
  const isAlternativeStrip = alternativeBenefitsFlag.value && appConfig.alternativeBenefits

  const strip = isAlternativeStrip ? appConfig.alternativeStrip : appConfig.benefitStrip
  const translationBase = isAlternativeStrip ? 'alternativeBenefitsStrip' : 'benefitsStrip'

  return strip.map<Benefit>((benefit) => {
    return {
      icon: benefit.icon,
      iconStyle: `stroke-width: ${benefit['stroke-width']}`,
      label: t(`${translationBase}.${benefit.icon}`),
    }
  })
})
</script>

<template>
  <div
    v-if="isBenefitsVisible"
    class="benefits position-relative">
    <CarouselWrapper
      class="benefits-wrapper container d-flex flex-row justify-content-around my-0"
      loop
      :autoplay="3000"
      hidden-control
      :step="1">
      <CarouselItem
        v-for="(benefit, index) in benefitsStrip"
        :key="index"
        class="benefits-item d-flex flex-row align-items-center">
        <Icon
          group="benefits"
          :icon="benefit.icon"
          :style="benefit.iconStyle" />
        <div class="p-2 text-4">
          {{ benefit.label }}
        </div>
      </CarouselItem>
    </CarouselWrapper>
  </div>
</template>

<style scoped lang="scss">
@use '@/legacy' as *;

.benefits {
  color: color('primary');
  background-color: color('secondary');

  @include media-breakpoint-down(md) {
    &::before {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
    }
  }
}

.benefits-item {
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 50%;
  }

  @include media-breakpoint-up(md) {
    width: unset;
  }
}

.benefits-wrapper {
  height: 37px;
}

:deep(.carousel-content) {
  @include media-breakpoint-up(md) {
    justify-content: space-around;
  }
}

.icon {
  $size: 28px;

  width: $size;
  height: $size;
}
</style>
